<template>
    <camera-layout>
        <latency-timer
            v-if="isStartLatencyTimer"
            :seconds="latencyTime"
            @timeUp="handleLatencyTimeUp"
        />
        <div v-if="isShowCloseButton" class="closeButton-wrapper">
            <close-button @click="handleCloseButton"/>
        </div>

        <video
            ref="video"
            autoplay
            muted
            playsinline
            class="background"
            :class="{
                'mirrored': isFrontCamera
            }"
        />

        
        <div v-if="isShowControls" class="timer">
            {{ latency }}
        </div>
        <div class="control-button-block pt-5">
                <camera-control-block
                    v-if="isShowControls || isShowChunkControls"
                    :isFrontCamera="isFrontCamera"
                    :isActiveCameraTimer="isActiveCameraTimer"
                    :isActiveSloMo="isActiveSloMo"
                    @changeCamera="changeCamera"
                    @cameraTimer="handleCameraTimer"
                    @flashlight="handleFlashlight"
                    @update:isActiveSloMo="isActiveSloMo = $event"
            />
        </div>
        <div style="position: fixed; bottom: 0; width: 100%; padding: 0 20px;">
            <div class="record-button-container mt-auto">
                <v-row class="flex-row pb-5" align="center" justify="center">
                    <v-col cols="3">
                        <div class="video-timer mb-1 mt-5 justify-center pb-5">
                            {{ displayVideoTimeFormat }}
                        </div>
                    </v-col>
                </v-row>
                <v-row class="flex-row pb-15" align="center" justify="center">
                    <v-col cols="8" class="d-flex justify-center position-absolute">
                        <div class="record-button-divison position-relative">
                            <v-progress-circular
                                v-if="videoTime"
                                :value="progressVideoTime"
                                :rotate="-90"
                                :size="90"
                                :width="5"
                                color="#ff8818"
                                class="record-button-circular-progress"
                            >
                            </v-progress-circular>
                            <record-button
                               :disable-video="disableVideo"
                               :useTimer="useTimer"
                               :touched="touched"
                               @click.stop.prevent
                               @touchstart.stop.prevent
                               @videoStart="videoStart"
                               @photoShoot="handlePhotoShoot"
                               @zoom-change="handleZoomChange"
                               @videoEnd="videoEnd"
                            />
                        </div>
                    </v-col>
                    <v-col cols="4" class="position-absolute right-4" style="right:20px;">
                        <div class="d-flex justify-center" style="gap:20px;" v-if="isShowChunkControls">
                            <v-btn icon @click="deleteLastChunkOfVideo">
                                <backspace-icon
                                    :height="32" :width="32" />
                            </v-btn>
        
                            <v-btn icon @click="finishRecording">
                                <tick-mark-icon
                                    :height="42" :width="42" />
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <canvas
            class="helper-canvas"
            ref="helper"
            style="position: absolute; height: 100vh; width: auto; top: -4000vh;"
            :width="widthVideo"
            :height="heightVideo"
        />
        <flashlight :show-flashlight="showFlashlight"/>
        <countdown-dialog
            v-if="this.isShowCountdownDialog"
            @change="changeDoAfterExecTimer"
            @close="handleCloseCountdownDialog"
        />
        <access-camera-dialog
            v-if="this.isShowAccessCameraDialog"
            @allowed="handleAllowedCamera"
            @close="handleCloseAccessCameraDialog"
        />
        <access-denied-camera-dialog
            v-if="this.isShowAccessDeniedCameraDialog"
            @close="handleCloseAccessDeniedCameraDialog"
        />
    </camera-layout>

</template>

<script>
import RecordButton from "@/components/app/posting/RecordButton";
import CameraLayout from "@/layouts/app/postingLayout/CameraLayout";
import CameraControlBlock from "@/components/app/posting/CameraControlBlock";
import LatencyTimer from "@/components/app/posting/LatencyTimer";
import Flashlight from "@/components/app/posting/Flashlight";
import CloseButton from "@/components/app/button/CloseButton";
import {LATENCY_TIME_STEP, STORY_ITEM_LENGTH_TIME} from "@/configs/constants";
import {mapMutations} from "vuex";
import moment from "moment";
import CountdownDialog from "@/components/app/dialogs/CountdownDialog";
import AccessCameraDialog from "@/components/app/dialogs/AccessCameraDialog";
import AccessDeniedCameraDialog from "@/components/app/dialogs/AccessDeniedCameraDialog";
import TickMarkIcon from "@/components/app/common/TickMarkIcon";
import BackspaceIcon from "@/components/app/common/BackspaceIcon";


export default {
    name: "RecordScreen",
    emits: ['change-camera'],
    data: () => ({
        stopVideo: false,
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        canvas: '',
        widthVideo: 0,
        heightVideo: 0,
        widthVideoContent: 0,
        heightVideoContent: 0,
        core: '',
        latencyTime: 0,
        isStartLatencyTimer: 0,
        latencyTimer: false,
        videoTime: false,
        videoTimer: false,
        isActiveFlashlight: false,
        showFlashlight: false,
        isActiveTorch: false,
        isShowCountdownDialog: false,
        isShowAccessCameraDialog: false,
        isShowAccessDeniedCameraDialog: false,
        useTimer: false,
        doAfterExecTimer: 'photo',
        isVideoRecording: false,
        recordedSessionsLength: 0,
        touched: false,
        isActiveSloMo:false,
        slowMotionTiming:[],
        slowMotionFrame:[],
        normalTiming:[],
        normalFrame:[],
        slowMotionTime:false,
        progress:0,
        totalProgress:0,
        finalProgress:0,
        displayVideoTime: false,
        displayVideoTimer:false,
        removeChunk:false,
        fromRemove:false,
        removedTime:0,
        mergedNormal:[],
        mergedSlow:[],
        mergedTotal:[],
    }),
    props: {
        disableAudio: {
            type: Boolean,
            default: false,
        },
        disableVideo: {
            type: Boolean,
            default: false,
        },
        isFrontCamera: {
            type: Boolean,
            default: false
        },
    },
    components: {
        AccessDeniedCameraDialog,
        AccessCameraDialog,
        CountdownDialog,
        RecordButton,
        CameraLayout,
        CameraControlBlock,
        CloseButton,
        LatencyTimer,
        Flashlight,
        TickMarkIcon,
        BackspaceIcon,
    },
    computed: {
        video() {
            return this.$refs.video
        },
        latency() {
            return this.latencyTime > 0 ? this.latencyTime + 's' : ''
        },
        videoTimeFormat() {
            if (this.videoTime !== false) {
                return moment.utc(this.videoTime * 1000).format('mm:ss');
            }
            return ''
        },

        displayVideoTimeFormat() {
            if (this.displayVideoTime !== false) {
                return moment.utc(this.displayVideoTime * 1000).format('mm:ss');
            }
            return ''
        },
        // isMirrored() {
        //     return this.isFrontCamera
        // },
        isShowControls() {
            return !(this.isStartLatencyTimer || this.videoTimer || this.displayVideoTimer)
        },
        isActiveCameraTimer() {
            return this.latencyTime > 0
        },
        isShowCloseButton() {
            return !(this.isStartLatencyTimer || (this.videoTimer && this.isVideoRecording))
        },
        isShowChunkControls() {
            return  this.videoTime !== false && !this.isVideoRecording && this.recordedSessionsLength !== 0
        },
        progressVideoTime() {

            if(this.recordedSessionsLength == 0) {
                this.totalProgress = 0;
                this.removedTime = 0;
            }
            
            if(this.isVideoRecording) {
                this.slowMotionTime++
            }

            
            
            if(this.removeChunk){
            } else {
                if(this.isActiveSloMo) {
                    // this.progress = ((this.slowMotionTime / STORY_ITEM_LENGTH_TIME) * 100) * 2
                    this.progress = ((this.slowMotionTime / STORY_ITEM_LENGTH_TIME) * 100) * 4
                } else {
                    this.progress = (this.slowMotionTime / STORY_ITEM_LENGTH_TIME) * 100
                }
            }

            if(this.removeChunk){
                this.finalProgress = (this.removedTime / STORY_ITEM_LENGTH_TIME) * 100;
                this.totalProgress = this.finalProgress
            } else {
                this.finalProgress = (this.totalProgress + this.progress);
            } 

            return (this.videoTime / STORY_ITEM_LENGTH_TIME) * 100 ? this.finalProgress : this.finalProgress;

            // return (this.videoTime / STORY_ITEM_LENGTH_TIME) * 100
        },
        progressCircularColor() {
            return `linear-gradient(to bottom, map-get($amber, 'base'), map-get($orange, 'accent-2') 15%, map-get($orange, 'accent-3') 40%, map-get($deep-orange, 'accent-3') 100%)`
        },
    },
    watch: {
        isShowAccessDeniedCameraDialog(val) {
            if (val && this.isShowAccessCameraDialog) {
                this.isShowAccessCameraDialog = false
            }
        },
    },
    methods: {
        handleCloseButton() {
            if (this.core) {
                this.core.stopCamera()
            }
            this.setStep(3)
            this.$emit('change-camera', false)
        },

        videoStart() {
            this.touched = true;
            this.removeChunk = false;
            if (this.videoTime == false) {
                this.videoTime = 0;
                this.displayVideoTime = 0;
                this.setType('video');

                this.core.videoInit();
                this.core.startRecordVideo();
            } else {
                this.core.resumeRecordVideo(); 
            }

            this.videoTimer = setInterval(() => {
                this.videoTime++;
                if (this.videoTime % STORY_ITEM_LENGTH_TIME === 0) {
                    this.videoEnd(true);
                }

                if(this.isActiveSloMo){
                    this.slowMotionTiming.push(this.videoTime)
                } else {
                    this.normalTiming.push(this.videoTime)
                }
            }, 1000);

            // slowmotion 0.25
            if(this.isActiveSloMo){
                this.displayVideoTimer = setInterval(() => {
                    this.displayVideoTime++;
                    if (this.displayVideoTime % STORY_ITEM_LENGTH_TIME === 0) {
                        this.videoEnd(true);
                    }
                }, 250);
            } else {
                this.displayVideoTimer = setInterval(() => {
                    this.displayVideoTime++;
                    if (this.displayVideoTime % STORY_ITEM_LENGTH_TIME === 0) {
                        this.videoEnd(true);
                    }
                }, 1000);
            }

            this.isVideoRecording = true;

            if (this.isFrontCamera && this.isActiveFlashlight) {
                this.showFlashlight = true;
            }
        },
        async handleZoomChange(zoomLevel) {
            await this.core.setZoom(zoomLevel);
        },

        videoEnd(isFinalStop = false) {
            clearTimeout(this.videoTimer);
            clearTimeout(this.displayVideoTimer);
            this.showFlashlight = false;

            if (this.videoTime > 0) {
                this.recordedSessionsLength++;
                if (isFinalStop || this.useTimer) {
                    this.touched = false;
                    this.isVideoRecording = false;

                    this.core.stopRecordVideo(async (blob) => {
                        await this.setVideo({ blob });
                        this.setVideoDuration(this.videoTime);
                        await this.setVideoDataUrl(URL.createObjectURL(blob));
                        this.core.stopCamera();
                        this.setStep(1);  // Move to next step after recording is done
                    });
                } else {
                    // Pause the recording temporarily to resume later
                    this.isVideoRecording = false;
                    
                    if(!this.isActiveSloMo && [...this.normalTiming].length){
                        this.normalFrame.push([...this.normalTiming])
                        this.$core.normalFrame = [...this.normalFrame] 
                    }
                    
                    if(this.isActiveSloMo && [...this.slowMotionTiming].length){
                        this.slowMotionFrame.push([...this.slowMotionTiming])
                        this.$core.slowMotionFrame = [...this.slowMotionFrame]
                    }


                    this.slowMotionTime = null;
                    this.slowMotionTiming = [];
                    this.normalTiming = [];
                    this.totalProgress += this.progress
                    
                    this.core.pauseRecordVideo(this.isActiveSloMo);
                }
            } else {
                this.photoShoot()
            }
        },

        finishRecording() {
            this.videoEnd(true);
        },

        deleteLastChunkOfVideo() {
            const { lastSessionDuration, recordedSessionsLength, removeFrom} = this.core.deleteLastSession();
            this.recordedSessionsLength = recordedSessionsLength

            if (recordedSessionsLength == 0) {
                this.displayVideoTime = 0;
                this.displayVideoTimer = false;
                this.videoTime = 0;                
                this.videoTimer = false;
                this.touched = false;
                this.finalProgress = 0;
                this.slowMotionFrame = [];
                this.normalFrame = [];
            } else {
                var normalLength = 0
                var slowLength = 0
                var mergedLength = 0
                this.removeChunk = true;
                var slowMotion = [];
                var normal = [];
                var removedFrom = "";
                slowMotion = this.$core.slowMotionFrame
                normal = this.$core.normalFrame

                if(removeFrom == 'true'){
                    this.$core.slowMotionFrame.pop()
                    this.slowMotionFrame.pop()
                    removedFrom = 'slow-motion';
                }

                if(removeFrom != 'true') {
                    this.$core.normalFrame.pop()
                    this.normalFrame.pop()
                    removedFrom = 'normal';
                }
                
                this.mergedNormal = normal.flat();
                this.mergedSlow = slowMotion.flat();
                this.mergedTotal = [...new Set([...this.mergedNormal, ...this.mergedSlow])];

                normalLength = this.mergedNormal.length
                slowLength = this.mergedSlow.length
                mergedLength = this.mergedTotal.length

                this.videoTime = normalLength + slowLength
                this.displayVideoTime = normalLength + (slowLength * 4)
                // this.displayVideoTime = normalLength + (slowLength * 2)
                this.removedTime = this.displayVideoTime
            }
        },

        handlePhotoShoot() {
            if (this.recordedSessionsLength > 0) {
                return;
            }
            this.isStartLatencyTimer = 1
            if (!this.latencyTime) {
                this.photoShoot()
            }
        },
        photoShoot() {
            let delay = 0;
            if (this.isFrontCamera && this.isActiveFlashlight) {
                this.showFlashlight = true;
                delay = 300
            }

            setTimeout(() => {
                this.setType('photo')
                this.core.toWidth = this.$refs.video.clientWidth
                this.core.toHeight = this.$refs.video.clientHeight
                this.core.frameCaptureBlob(blob => this.setImage({image: blob}))
                this.setImageDataUrl({
                    image: this.core.frameCapture(),
                    width: this.width,
                    height: this.height,
                })
                this.core.stopCamera()
                this.setStep(1)
                this.showFlashlight = false;
            }, delay)
        },
        async changeCamera(emitUpdate = true) {
            let status = await this.core.changeCamera()
            if (status) {
                this.isActiveTorch = false
                this.isActiveFlashlight = false
                if (emitUpdate) {
                    this.$emit('change-camera', !this.isFrontCamera)
                }
            }
        },
        handleCameraTimer() {
            this.latencyTime = !this.latencyTime ? LATENCY_TIME_STEP : 0
            if (this.latencyTime) {
                this.isShowCountdownDialog = true
            }
        },
        handleFlashlight() {
            this.isActiveTorch = !this.isActiveTorch
            this.isActiveFlashlight = !this.isActiveFlashlight
            
            this.core.useTorch(this.isActiveTorch)
        },
        handleLatencyTimeUp() {
            this.isStartLatencyTimer = 0
            this.useTimer = true
            if (this.doAfterExecTimer === 'photo') {
                this.photoShoot()
            }
            if (this.doAfterExecTimer === 'video') {
                this.videoStart()
            }
        },
        handleCloseCountdownDialog() {
            this.latencyTime = 0
            this.isShowCountdownDialog = false
        },
        handleCloseAccessCameraDialog() {
            this.isShowAccessCameraDialog = false
            this.setStep(3)
        },
        handleCloseAccessDeniedCameraDialog() {
            this.isShowAccessDeniedCameraDialog = false
            this.setStep(3)
        },
        handleAllowedCamera() {
            this.isShowAccessCameraDialog = false
            this.loadCamera()
        },
        async loadCamera() {
            await this.setResolutions();

            if (this.isFrontCamera) {
                await this.changeCamera(false);
            }

            await this.core.checkZoomSupport();

            const self = this;
            window.addEventListener('orientationchange', function () {
                self.setResolutions();
            });
        },
        changeDoAfterExecTimer(value) {
            this.doAfterExecTimer = value
            this.isShowCountdownDialog = false
        },
        async setResolutions() {
            if (this.$refs.video) {
                this.width = this.$refs.video.clientWidth
                this.height = this.$refs.video.clientHeight
            }
            this.core = this.$core.storyCore;
            this.core.init(this.$refs.helper, this.$refs.video, !this.disableAudio)
            try {
                await this.core.showVideoFromCamera(([w, h]) => {
                    this.widthVideo = w
                    this.heightVideo = h
                })
            } catch (e) {
                this.isShowAccessDeniedCameraDialog = true
            }

        },
        ...mapMutations([
            'hideCameraScreen',
            'setStep',
            'setImage',
            'setVideo',
            'setType',
            'setImageDataUrl',
            'setVideoDataUrl',
            'setVideoDuration',
        ])
    },
    async mounted() {
        await this.loadCamera()

        if (!this.core.stream && !this.isShowAccessCameraDialog && !this.isShowAccessDeniedCameraDialog) {
            this.isShowAccessCameraDialog = true;
        } else if (this.core.stream) {
            this.isShowAccessCameraDialog = false;
        }
    }
}
</script>

<style scoped lang="scss">
.mirrored {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.record-button-container,
.control-button-block,

.timer {
    text-align: center;
    text-align: -webkit-center;
    z-index: 10;
}

#cameraFileInput {
    display: none;
}

#pictureFromCamera {
    width: 100%;
    height: auto;
    margin-top: 16px;
}

.btn {
    display: inline-block;
    background-color: #00b531;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.btn:hover {
    filter: brightness(0.9);
}

.closeButton-wrapper {
    position: absolute;
    width: 3em;
    left: 2em;
    top: 3em;
    z-index: 99999;
}

.control-button-block {
    // position: relative;
    // margin-bottom: 20px;
    // margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px 20px;
    margin-top: 25.5px;
}

.timer {
    font-size: 1.7em;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.background.full-width {
    background: black;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    min-height: 56.25vw;
}

.background {
    background: black;
    position: absolute;
    z-index: -1;
    object-fit: cover;
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

video {
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

.video-timer {
    color:#FFFFFF;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.record-button-circular-progress {
    position: absolute;
    left: 50%;
    top: -4px;
    transform: translateX(-50%);
}
</style>
