var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"column-layout"},[_c('v-col',{class:{
                    active: _vm.isActiveCameraTimer
                },on:{"click":_vm.handleCameraTimer}},[_c('camera-timer')],1),_c('v-col',{class:{
                    active: _vm.isActiveSloMo
                },on:{"click":_vm.handleSloMo}},[_c('slo-mo')],1),_c('v-col',{class:{
                    active: _vm.isActiveFlashlight
                },on:{"click":_vm.handleFlashlight}},[_c('flashlight')],1),_c('v-col',{class:{
                    active: _vm.isFrontCamera
                },on:{"click":_vm.handleChangeCamera}},[_c('change-camera')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }